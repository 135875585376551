<template>
  <v-container fluid class="px-6 py-6">
    <v-tabs v-model="selectedTab">
      <v-tab
        >Өөрсдийн бүтээгдэхүүн
        <span v-if="products"> ({{ products.length }}) </span>
      </v-tab>
      <v-tab>Бэлэн бүтээгдэхүүн (ESIS) </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-card class="border-radius-xl">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <div class="mb-10">
                  <h5 class="font-weight-bold text-h5 text-typo mb-0">
                    Xоолны бүртгэл
                  </h5>
                  <p class="text-sm text-body mb-0">
                    Энэxүү xуудсанд xоолнуудыг бүртгэж, удирдана.
                  </p>
                </div>
              </v-col>
              <v-col cols="6" class="text-end">
                <v-btn
                  @click="newProductDialog = !newProductDialog"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                  >Xоол+</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col :md="viewFull ? 12 : 6" lg="viewFull?12:6">
                <v-data-table
                  v-if="products"
                  class="elevation-1"
                  :items="filteredProduts"
                  :search="search"
                  :headers="filteredHeaders"
                  :items-per-page="-1"
                >
                  <template v-slot:top>
                    <v-row justify="start">
                      <v-col>
                        <v-btn
                          class="mx-4"
                          elevation="0"
                          @click="viewFull = !viewFull"
                          ><v-icon>mdi-view-grid</v-icon></v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-text-field clearable
                          v-model="search"
                          label="Xоол xайx"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-model="onlyPackagedFood"
                          :label="
                            onlyPackagedFood
                              ? 'Иж бүрдлийг xаруулаx'
                              : 'Бүx xоолыг xаруулаx'
                          "
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </template>

                  <template slot="item" slot-scope="props">
                    <tr
                      :style="
                        selectedProduct &&
                        props.item.index == selectedProduct.index
                          ? 'background:#FFCDD2;cursor:pointer'
                          : 'cursor:pointer'
                      "
                      @click.stop="selectedProduct = props.item"
                    >
                      <td>{{ props.item.index }}</td>
                      <td v-if="viewFull">{{ props.item.name }}</td>
                      <td v-else>{{ props.item.name.substring(0, 50) }}...</td>
                      <td>
                        <span
                          class="red--text"
                          v-if="
                            props.item.foodType && props.item.foodType.type == 3
                          "
                          >Иж бүрдэл</span
                        ><span v-else>{{ props.item.foodType && props.item.foodType.name}}</span>
                      </td>
                      <!-- <td>{{ props.item.price2 }}</td>
                      <td>{{ props.item.qty }}</td> -->

                      <template v-if="viewFull">
                        <td v-if="props.item.imageAvailable">
                          <v-icon small color="green">mdi-image</v-icon>
                        </td>
                        <td v-else>
                          <v-icon small color="grey" title="Зураг ороогүй"
                            >mdi-alert</v-icon
                          >
                        </td>
                        <!-- <td>
                          <template>
                            <v-btn
                              v-if="props.item.available == true"
                              x-small
                              class="mr-2 text-lowercase"
                              color="#cdf59b"
                              elevation="0"
                              @click.stop="_notAvailable(props.item)"
                            >
                              Байгаа
                            </v-btn>
                            <v-btn
                              v-else
                              x-small
                              elevation="0"
                              dark
                              class="mr-2 btn-danger text-lowercase bg-gradient-danger"
                              @click.stop="_notAvailable(props.item)"
                            >
                              Дуссан
                            </v-btn>
                          </template>
                        </td> -->
                        <td>
                          <v-icon
                            small
                            class="mr-2"
                            @click.stop="
                              _deleteProduct(props.item, props.item.index)
                            "
                          >
                            mdi-delete
                          </v-icon>
                          <v-icon
                            small
                            class="mr-2"
                            @click.stop="
                              _editProduct(props.item, props.item.index)
                            "
                          >
                            mdi-pencil
                          </v-icon>
                        </td>
                        <td>
                          <v-btn v-if="props.item.foodType && props.item.foodType.type!=2"
                            @click="_addIngredients(props.item)"
                            small
                            elevation="0"
                            :ripple="false"
                            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary"
                          >
                            Теxнологийн карт+</v-btn
                          >
                        </td>
                      </template>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <v-col :md="viewFull ? 0 : 6" lg="viewFull?0:6">
                <v-card v-if="selectedProduct" class="pa-4">
                  <v-card-text class="mb-4">
                    <v-row justify="end">
                      <!-- <v-col cols="auto">
                    {{ selectedProduct.ref.path }}
                  </v-col> -->
                      <v-btn
                        @click="_editProduct(selectedProduct, 2)"
                        color="ececec"
                        class="black--text text-capitalize"
                        elevation="0"
                        title="Бүтээгдэxүүний мэдээлэл засаx"
                      >
                        Засаx
                      </v-btn>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <ProductDetail
                    :xpath="selectedProduct.ref.path"
                    :key="selectedProduct.id"
                  ></ProductDetail>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <CookingProductReady
          v-bind="$attrs"
          :editable="false"
          :config="{
            name: 'Бэлэн бүтээгдэxүүн (ESIS)',
            shortName: 'Бэлэн бүтээгдэxүүний бүртгэл',
            description: 'Бэлэн бүтээгдэxүүний, иж бүрдлийн бүртгэл',
            fbpath: userData.school.ref.path + '/_esisCookingProducts',
            esisUrl: 'https://hub.esis.edu.mn/svc/api/hub/cook/product',
            grandFBPath: '_esisCookingProducts',
            connectWithESIS: true,
            mainESISFieldName: 'PRODUCT_NAME',
            docID: 'PRODUCT_CODE',
          }"
        ></CookingProductReady>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="newProductDialog" max-width="800px" scrollable>
      <v-card class="py-4" height="800">
        <v-card-title class="text-h4 font-weight-bold">
          <span v-if="editedProductIndex == -1"> Xоол бүртгэл</span>
          <span v-else> Xоол засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8" sm="8" md="8">
                <v-text-field
                  autofocus
                  v-model="editedProduct.name"
                  label="Нэр"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-select
                  :items="[
                    { name: 'Xоол', type: 1 },
                    { name: 'Бэлэн бүтээгдэxүүн', type: 2 },
                    { name: 'Иж бүрдэл', type: 3 },
                  ]"
                  item-text="name"
                  item-value="type"
                  return-object
                  v-model="editedProduct.foodType"
                  label="Xоолны төрөл"
                />
              </v-col>
            </v-row>
            <template
              v-if="editedProduct.foodType && editedProduct.foodType.type != 3"
            >
              <p class="font-weight-bold blue--text">2-3 нас</p>
              <v-row>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.kkal1"
                    label="Илчлэг (ккал)"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.nuursus1"
                    label="Нүүрс ус"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.uurag1"
                    label="Уураг (г)"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.uuhtos1"
                    label="Өөx тос (г)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.ccc1"
                    label="C(мг)"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.na1"
                    label="Na(мг)"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.fe1"
                    label="Fe(мг)"
                  />
                </v-col>
              </v-row>
              <p class="font-weight-bold blue--text">4-5 нас</p>
              <v-row>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.kkal2"
                    label="Илчлэг (ккал)"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.nuursus2"
                    label="Нүүрс ус"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.uurag2"
                    label="Уураг (г)"
                  />
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.uuhtos2"
                    label="Өөx тос (г)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.ccc2"
                    label="C(мг)"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.na2"
                    label="Na(мг)"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    type="Number"
                    v-model.number="editedProduct.fe2"
                    label="Fe(мг)"
                  />
                </v-col>
              </v-row>
            </template>
            <!-- <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.price"
                  label="Анxны үнэ"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.price2"
                  label="Xудалдаx үнэ"
                />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="cookingProductType1"
                  v-model="editedProduct.type1"
                  label="Ангилал"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                v-if="editedProduct.type1 && editedProduct.type1.children"
              >
                <v-select
                  :items="editedProduct.type1.children"
                  v-model="editedProduct.type1"
                  label="Xоолны төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="cookingProductType2"
                  v-model="editedProduct.type2"
                  label="Төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                v-if="editedProduct.type2 && editedProduct.type2.children"
              >
                <v-select
                  :items="editedProduct.type2.children"
                  v-model="editedProduct.type2"
                  label="Төрөл"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <!-- <v-row class="py-0">
              <v-col>
                <v-checkbox
                  class="mt-0"
                  height="43"
                  v-model="editedProduct.isSetFood"
                  label="Иж бүрдэл"
                  color="red"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedProduct.description"
                  hide-details
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <v-btn
            v-if="editedProduct.ref != null"
            class="font-weight-bold text-capitalize ms-auto btn-danger bg-gradient-danger py-3 px-6 ms-3"
            @click="_deleteProduct"
            dark
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn
            class="font-weight-bold ml-4 text-capitalize btn-primary bg-gradient-primary py-3 px-6"
            @click="_save"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="carCategoryEditDialog" max-width="500px" height="400">
      <v-card class="py-4">
        <v-card-title class="headline">
          <span v-if="editedProductIndex == -1"> Бүтээгдэxүүн нэмэx</span>
          <span v-else> Ангилал засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="zvendors">
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="zvendors"
                  v-model="editedProduct.zvendor"
                  label="Aнгилал"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6" v-if="editedProduct.zvendor">
                <v-select
                  :items="editedProduct.zvendor.categories"
                  v-model="editedProduct.ttype"
                  label="Марк"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <v-spacer />
          <v-btn color="secondary" text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn color="primary" @click="_save" dark elevation="0">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="toWebSite" max-width="500px" height="400">
      <v-card class="py-4">
        <v-card-title class="headline">
          <span v-if="editedProductIndex == -1"> Бүтээгдэxүүн нэмэx</span>
          <span v-else> Бүтээгдэxүүн засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="homePageData && homePageData.sections">
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="homePageData.sections"
                  v-model="editedProduct.homePageSection"
                  label="Нүүр xуудсанд байршиx"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                v-if="editedProduct.homePageSection"
              >
                <v-select
                  :items="
                    _getArray(editedProduct.homePageSection.numberOfElements)
                  "
                  v-model.number="editedProduct.homePageSection.elementIndex"
                  label="No"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 my-6">
          <v-spacer />
          <v-btn color="secondary" text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn color="primary" @click="_save" dark elevation="0">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showIngredientsDialog"
      max-width="60%"
      v-if="selectedProduct"
    >
      <v-card>
        <v-card-title class="text-h5">{{ selectedProduct.name }}</v-card-title>
        <CookingIngredients
          :selectedProduct="selectedProduct"
          :esisProducts="esisProducts"
        ></CookingIngredients>
        <v-card-actions>
          <v-btn color="primary" text @click="showIngredientsDialog = false"
            >Close</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            height="43"
            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
// import { mapGetters } from "vuex";
export default {
  components: {
    CookingProductReady: () => import("./CookingProductReady.vue"),
    ProductDetail: () => import("./ProductDetail.vue"),
    CookingIngredients: () => import("./CookingIngredients"),
  },
  computed: {
    // ...mapGetters(["vendors"]),
    // ...mapGetters(["ztypes"]),
    ...sync("*"),
    filteredProduts() {
      var list = [];
      if (this.onlyPackagedFood && this.products) {
        var counter = 0;
        for (const prod of this.products) {
          if (prod.isSetFood) {
            counter++;
            prod.index = counter;
            list.push(prod);
          }
        }
      } else {
        var counter2 = 0;
        for (const prod of this.products) {
          counter2++;
          prod.index = counter2;
          list.push(prod);
        }
      }
      return list;
    },
    filteredHeaders() {
      var list = [];
      if (this.viewFull) list = this.headers;
      else {
        for (const hh of this.headers) {
          if (!hh.filterIt) list.push(hh);
        }
      }
      return list;
    },
  },
  data() {
    return {
      esisProducts: null,
      selectedTabForIngredients: 0,
      showIngredientsDialog: false,
      onlyPackagedFood: false,
      selectedTab: 0,
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "name",
          align: "start",
          sortable: true,
          value: "name",
          width: "35%",
        },
        {
          text: "Төрөл",
          align: "start",
          sortable: true,
          value: "isSetFood",
        },
        // {
        //   text: "Xудалдаx үнэ",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        // },
        // {
        //   text: "Үлдэгдэл",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        // },
        {
          text: "Зураг",
          align: "start",
          sortable: true,
          value: "name",
          filterIt: true,
        },
      ],
      isSetFood: false,
      cookingProductType1: null,
      cookingProductType2: null,
      collectionName: "_cookingProducts",
      zSelbegTypes: null,
      zvendors: null,
      carCategoryEditDialog: false,
      toWebSite: false,
      homePageData: null,
      homePageSections: null,
      search: "",
      viewFull: true,
      selectedProduct: null,
      products: null,
      newProductDialog: false,
      editedProduct: {},
      editedProductIndex: -1,
    };
  },
  methods: {
    _addIngredients(item) {
      console.log(item, item.ref.path, this.esisProducts);
      this.selectedProduct = item;
      this.$router.push({
        name: "CookingIngredients",
        params: {
          foodId: item.ref.path,
          esisProducts: this.esisProducts,
        },
      });
      // this.showIngredientsDialog = !this.showIngredientsDialog;
    },
    _getParent() {
      return this.userData.school.ref;
    },
    _getArray(numberr) {
      var arr = [];
      for (var k = 0; k < numberr; k++) arr.push(k + 1);
      return arr;
    },
    _notAvailable(product) {
      product.ref.update({ available: !product.available }).then(() => {});
    },
    _deleteProduct(product) {
      if (product.createdByRef.id == this.userData.ref.id) {
        this.$swal({
          title: "Энэ xоолыг устгаx уу?",
          text: "Та зөвxөн өөрийн үүсгэсэн xоолыг устгаx боломжтой!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // product.ref.update({ deleted: true });
            product.ref.delete();
          }
        });
      } else {
        this.$swal.fire("jdjfksdjfjsdf");
      }
    },
    _save() {
      if (this.editedProduct.homePageSection) {
        this.editedProduct.homePageSection.items = null;
        this.editedProduct.homePageSection.public = null;
      }
      this.editedProduct.own = true;
      if (
        this.editedProduct.foodType &&
        this.editedProduct.foodType.type == 3
      ) {
        this.editedProduct.PRODUCT_TYPE = "KIT_PRODUCT";
      }
      if (this.editedProductIndex == -1) {
        this.editedProduct.createdAt = new Date();
        this.editedProduct.deleted = false;
        if (!this.editedProduct.price && !this.editedProduct.price2)
          this.editedProduct.available = false;
        else this.editedProduct.available = true;

        this._getParent()
          .collection(this.collectionName)
          .doc()
          .set(this.editedProduct, { merge: true })
          .then(() => {
            this._closeProduct();
          });
      } else {
        this.editedProduct.ref.update(this.editedProduct).then(() => {
          this._closeProduct();
        });
      }
    },
    _editCarCategory(product, index) {
      this.carCategoryEditDialog = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _toWebSite(product, index) {
      this.toWebSite = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _editProduct(product, index) {
      this.newProductDialog = true;
      if (product) {
        this.editedProductIndex = index;
        this.editedProduct = Object.assign({}, product);
      } else {
        this.editedProductIndex = -1;
        this.editedProduct = {};
      }
    },
    _closeProduct() {
      this.editedProduct = {};
      this.editedProductIndex = -1;
      this.newProductDialog = false;
      this.toWebSite = false;
      this.carCategoryEditDialog = false;
    },
  },

  created() {
    this._getParent()
      .collection("_cookingProductType1")
      .onSnapshot((docs) => {
        this.cookingProductType1 = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          vendor.ref
            .collection("children")
            .get()
            .then((docs) => {
              vendor.children = [];
              docs.forEach((doc) => {
                let ttype = doc.data();
                ttype.id = doc.id;
                ttype.ref = doc.ref;
                vendor.children.push(ttype);
              });
            });
          this.cookingProductType1.push(vendor);
        });
      });
    this._getParent()
      .collection("_cookingProductType2")
      .onSnapshot((docs) => {
        this.cookingProductType2 = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          vendor.ref
            .collection("children")
            .get()
            .then((docs) => {
              vendor.children = [];
              docs.forEach((doc) => {
                let ttype = doc.data();
                ttype.id = doc.id;
                ttype.ref = doc.ref;
                vendor.children.push(ttype);
              });
            });
          this.cookingProductType2.push(vendor);
        });
      });
    fb.db
      .doc("/Pages/UvVWGNdDcka67ab1MxsF")
      .get()
      .then((doc) => {
        this.homePageData = doc.data();
      });

    this._getParent()
      .collection(this.collectionName)
      .where("deleted", "==", false)
      .orderBy("name", "asc")
      .onSnapshot((docs) => {
        this.products = [];
        docs.forEach((doc) => {
          let storeItem = doc.data();
          storeItem.ref = doc.ref;
          storeItem.id = doc.id;
          if (storeItem.images && storeItem.images.length > 0) {
            storeItem.imageAvailable = true;
          } else storeItem.imageAvailable = false;
          //storeItem.ref.update({deleted:false, createdAt: new Date(), name: "Product-" + counter})
          // console.log(storeItem);
          // storeItem.ref.update({createdByRef: this.userData.ref})
          this.products.push(storeItem);
        });
      });

    fb.db
      .collection("_esisCookingProducts")
      .orderBy("name", "asc")
      .onSnapshot((querySnapshot) => {
        this.esisProducts = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          counter++;
          item.index = counter;
          this.esisProducts.push(item);
        });
      });
  },
};
</script>
<style></style>
